import pic1 from "../../img/Car.png";
import pic2 from "../../img/doctor.png";
import pic3 from "../../img/shop.png";

export const data = [
  {
    id: 1,
    img: pic1,
    tittle: "Car Repair service",
    github: "https://github.com/Alaminkhan907/car-service",
    demo: "https://genius-car-service-cee48.web.app/",
  },
  {
    id: 2,
    img: pic2,
    tittle: "Dental booking service",
    github: "https://github.com/Alaminkhan907/Dental_Service_project",
    demo: "https://doctor.maitea.shop/",
  },
  {
    id: 3,
    img: pic3,
    tittle: "Basic shop",
    github: "http://maitea.shop/",
    demo: "http://maitea.shop/",
  },
];
